import NavBar from "../components/navigation/NavBar"
import React, {useContext, useEffect, useState} from "react"
import {useTranslation} from "next-i18next"
import Link from "next/link"
import Footer from "../components/footer/Footer"
import MbpHead from "../components/head/MbpHead"
import Typography from "../components/reusable/Typography/Typography"
import {serverSideTranslations} from "next-i18next/serverSideTranslations"
import nextI18NextConfig from "../next-i18next.config"
import {getPageData} from "../pageData"
import HomeCatBoxes from "../components/home/homeCatBoxes/HomeCatBoxes"
import FindPerfectBike from "../components/home/FindPerfectBike/FindPerfectBike"
import {useRouter} from "next/router"
import LayoutContext from "../context/LayoutContext"
import {fetchPost} from "../firebaseAdmin/fetchPost"
import {logger} from "../utility/Helper"
import {clarityEvents} from "../config/googleAnalytics/events"
import {strapiCollections} from "../utility/strapi/collections"

const FourOFour = () => {
	const router = useRouter()
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const [query, setQuery] = useState({params: null})

	const path = router.asPath
	useEffect(() => {
		if (typeof window !== "undefined") {
			window.clarity("set", clarityEvents.emptyPage, "User landed on an empty page")
		}

		if (path.includes("?status")) {
			setQuery(prev => ({
				...prev, params: [path.split("?")[1].split("&")[0].split("=")[1],
					path.split("?")[1].split("&")[1].split("=")[1]]
			}))
		}
	}, [router.query])

	useEffect(() => {
		if (typeof window !== "undefined") {
			const referrer = sessionStorage.getItem("referrer")
			fetchPost(`${process.env.NEXT_PUBLIC_APP_URL}/api/mbp/slack/page-not-found`, {
				referrer: referrer ?
					referrer :
					"An Unknown external link, double check marketing urls.",
				currentLink: router.asPath
			})
				.then(response => response.json())
				.then(() => {
					logger.log("404 sent to slack")
				})
				.catch(err => logger.error("Storing error in SLACK failed:", err))
		}
	}, [])
	return (
		<div>
			<div>
				<MbpHead>
					<title>
						{t("Seite nicht gefunden")}
					</title>
					<meta name="description"
						content={t("Wir konnten diese Seite leider nicht finden.")}/>
				</MbpHead>
				<NavBar/>
				<main>
					<div className={"container"}>
						<div className={"row justify-content-center"}>
							<div className={"col-11 container text-left text-md-center"}>
								{query?.params?.[0] === "unavailable_product" ?
									<Typography style={{fontSize: isMobile ? "28px" : "36px", lineHeight: isMobile ? "36px" : "44px", marginBottom: "3rem", paddingTop: "5rem"}}
										variant="heading1"
										semanticTag="h1">
										{t("product-unavailable")}
									</Typography> :
									<Typography style={{fontSize: isMobile ? "28px" : "36px", lineHeight: isMobile ? "36px" : "44px", marginBottom: "3rem", paddingTop: "5rem"}}
										variant="heading1"
										semanticTag="h1">
										{t("Wir konnten diese Seite leider nicht finden.")}
									</Typography>}
								{router.locale === "de" ? <Typography style={{fontSize: "16px", lineHeight: "24px", paddingBottom: "2rem"}}
									variant="bodySm"
									semanticTag="p">
								Geh zur <Link href="/" ><span className="rounded text-decoration-none px-1 cursor-pointer"
										style={{backgroundColor: "#FFE9C2", fontWeight: 700}}> Homepage </span></Link>oder entdecke einige unserer Top-Modelle weiter unten.	</Typography> :
									<Typography style={{fontSize: "16px", lineHeight: "24px", paddingBottom: "2rem"}}
										variant="bodySm"
										semanticTag="p">
								Rendez-vous sur <Link href="/" ><span className="rounded text-decoration-none px-1 cursor-pointer"
											style={{backgroundColor: "#FFE9C2", fontWeight: 700}}> la page daccueil </span></Link>, ou consultez quelques-uns de nos bestsellers ci-dessous.</Typography>}
							</div>
							<HomeCatBoxes is404Page
								hideTopModels />
							<div className="col-12 py-4 mb-3 mt-3">
								<FindPerfectBike />
							</div>
						</div>
					</div>
				</main>
				<Footer/>
			</div>
		</div>
	)
}

export async function getStaticProps({locale}) {
	const pageData = (await getPageData(locale, [], [], [strapiCollections.homepage]))

	return {
		props: {
			...pageData,
			...(await serverSideTranslations(locale, ["common", "URL", "search", "filters"], nextI18NextConfig))
		}
	}
}

export default FourOFour
